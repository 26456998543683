import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Essays = () => (
  <Layout>

  </Layout>
)

export default Essays
